import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getUserRole } from "@grudder/apiCalls";

interface UserRolesState {
  userRoles: any[];
  loading: boolean;
  error: string | null;
  singleton: any | null;
}

// Define the initial state
const initialState: UserRolesState = {
  userRoles: [],
  loading: false,
  error: null,
  singleton: null,
};

// Define an async thunk to fetch user role details
export const fetchUserRoleDetails = createAsyncThunk(
  "userRoles/fetchUserRoleDetails",
  async (roleId: string, { getState, dispatch }) => {
    const state = getState() as { userRoles: UserRolesState; permissions: any };
    const userRoles: any[] = state.userRoles.userRoles;
    const allPermissions: any[] = state.permissions.allPermissions;
    const allowedPermissions = allPermissions?.filter((p: any) =>
      p.allowedUserTypes.find((role: any) => role?._id === roleId)
    );
    const existingRole = userRoles.find(
      (userRole: any) => userRole._id === roleId
    );

    if (existingRole) {
      dispatch(
        updateUserRoleDetails({
          ...existingRole,
          permissions: allowedPermissions,
        })
      );
      return existingRole;
    } else {
      const response = await getUserRole(roleId);
      dispatch(
        updateUserRoleDetails({
          ...response,
          permissions: allowedPermissions,
        })
      );
      return response;
    }
  }
);

// Define the slice
const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    setUserRoles: (state, action: PayloadAction<any[]>) => {
      const userRoleMap = new Map(
        state.userRoles.map((userRole) => [userRole._id, userRole])
      );
      action.payload.forEach((userRole) => {
        const currentUserRole = userRoleMap.get(userRole._id);
        if (
          currentUserRole &&
          new Date(currentUserRole.updatedAt).getTime() >=
            new Date(userRole.updatedAt).getTime()
        ) {
          return;
        }
        userRoleMap.set(userRole._id, userRole);
      });

      const updatedUserRoles = Array.from(userRoleMap.values());
      state.userRoles = updatedUserRoles;
    },
    addUserRole: (state, action: PayloadAction<any>) => {
      state.userRoles = [...state.userRoles, action.payload];
    },

    removeUserRole: (state, action: PayloadAction<any>) => {
      state.userRoles = state.userRoles.filter(
        (userRole) => userRole?._id !== action.payload
      );
    },
    updateUserRole: (state, action: PayloadAction<any>) => {
      const existingIndex = state.userRoles.findIndex(
        (role) => role._id === action.payload._id
      );
      if (existingIndex !== -1) {
        state.userRoles[existingIndex] = action.payload;
      } else {
        state.userRoles.push(action.payload);
      }
    },
    updateUserRoleDetails: (state, action: PayloadAction<any | null>) => {
      state.singleton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoleDetails.pending, (state) => {
        console.log("pending:here");
        state.loading = true;
      })
      .addCase(fetchUserRoleDetails.fulfilled, (state, action) => {
        console.log("success:here");
        state.loading = false;
        state.singleton = action.payload;
      })
      .addCase(fetchUserRoleDetails.rejected, (state) => {
        console.log("failed:here");
        state.loading = false;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  setUserRoles,
  addUserRole,
  updateUserRole,
  removeUserRole,
  updateUserRoleDetails,
} = userRolesSlice.actions;

export default userRolesSlice.reducer;
