// noteSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getNotesForLeadId } from "@grudder/apiCalls";

interface Note {
  _id: string;
  lead?: { _id: string };
  createdAt: string;
}

interface NoteState {
  notes: Note[];
  total: number;
  loading: boolean;
  loadingMore: boolean;
}

const initialState: NoteState = {
  notes: [],
  total: 0,
  loading: false,
  loadingMore: false,
};

// Define an async thunk to fetch lead details
export const fetchNotesForLead = createAsyncThunk<
  { data: Note[]; total: number },
  string
>("notes/fetchNotesForLead", async (leadId, { getState, dispatch }) => {
  const notesState = getState() as { notes: NoteState };
  const notes: Note[] = notesState.notes?.notes ?? [];
  const notesTotal: number = notesState.notes?.total ?? 0;

  // Simplify the condition
  const notesForTheLeadExist =
    notes.length > 0 && notes.every((note) => note.lead?._id === leadId);

  if (!notesForTheLeadExist) {
    dispatch(startNoteLoading());
    dispatch(initializeNotes({ notes: [], total: 0 }));
    const response = await getNotesForLeadId(leadId);
    return response;
  }

  const returnData = { data: notes, total: notesTotal };
  return returnData;
});

// Define an async thunk to fetch lead details
export const loadMoreNotesForLead = createAsyncThunk<
  { data: Note[]; total: number },
  { leadId: string; limit: number; skip: number }
>("notes/loadMoreNotesForLead", async ({ leadId, limit, skip }) => {
  const response = await getNotesForLeadId(leadId, limit, skip);
  return response;
});

const noteSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    initializeNotes(
      state,
      action: PayloadAction<{ notes: Note[]; total: number }>
    ) {
      state.notes = [...action.payload.notes].sort(
        (noteA, noteB) =>
          new Date(noteB.createdAt).getTime() -
          new Date(noteA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
    startNoteLoading(state) {
      state.loading = true;
    },
    addNewNote(state, action: PayloadAction<Note>) {
      const noteIndex = state.notes.findIndex(
        (note) => note._id === action.payload._id
      );
      const updatedNotesMap = new Map(
        state.notes.map((note) => [note._id, note])
      );
      updatedNotesMap.set(action.payload._id, action.payload);
      state.notes = Array.from(updatedNotesMap.values()).sort(
        (noteA, noteB) =>
          new Date(noteB.createdAt).getTime() -
          new Date(noteA.createdAt).getTime()
      );
      if (noteIndex === -1) {
        state.total += 1;
      }
    },
    handleAddNotes(
      state,
      action: PayloadAction<{ notes: Note[]; total: number }>
    ) {
      const updatedNotesMap = new Map(
        state.notes.map((note) => [note._id, note])
      );
      action.payload.notes.forEach((newNote) => {
        updatedNotesMap.set(newNote._id, newNote);
      });
      state.notes = Array.from(updatedNotesMap.values()).sort(
        (noteA, noteB) =>
          new Date(noteB.createdAt).getTime() -
          new Date(noteA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotesForLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotesForLead.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = [...action.payload.data].sort(
          (noteA, noteB) =>
            new Date(noteB.createdAt).getTime() -
            new Date(noteA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(fetchNotesForLead.rejected, (state) => {
        state.loading = false;
        state.notes = [];
        state.total = 0;
      })
      .addCase(loadMoreNotesForLead.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(loadMoreNotesForLead.fulfilled, (state, action: any) => {
        state.loadingMore = false;
        const updatedNotesMap = new Map(
          state.notes.map((note) => [note._id, note])
        );
        action.payload.data.forEach((newNote: Note) => {
          updatedNotesMap.set(newNote._id, newNote);
        });
        state.notes = Array.from(updatedNotesMap.values()).sort(
          (noteA, noteB) =>
            new Date(noteB.createdAt).getTime() -
            new Date(noteA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(loadMoreNotesForLead.rejected, (state) => {
        state.loadingMore = false;
        state.notes = [];
        state.total = 0;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const { initializeNotes, addNewNote, handleAddNotes, startNoteLoading } =
  noteSlice.actions;
export default noteSlice.reducer;
