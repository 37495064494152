import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface StatusesState {
  statuses: any[];
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: StatusesState = {
  statuses: [],
  loading: false,
  error: null,
};

// Define the slice
const statusesSlice = createSlice({
  name: "statuses",
  initialState,
  reducers: {
    setStatuses: (state, action: PayloadAction<any[]>) => {
      state.statuses = action.payload;
    },
    addStatus: (state, action: PayloadAction<any>) => {
      state.statuses = [...state.statuses, action.payload];
    },
    updateStatus: (state, action: PayloadAction<any>) => {
      const updatedStatus = action.payload;
      const index = state.statuses.findIndex(
        (status) => status._id === updatedStatus._id
      );
      if (index !== -1) {
        state.statuses[index] = updatedStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setStatuses, addStatus, updateStatus } = statusesSlice.actions;

export default statusesSlice.reducer;
