import { AUTH_GET } from "@grudder/app/actions-server";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL!;
let apiAbortController: AbortController | null = null;

const getLeads = async (
  limit: number = 10,
  skip: number = 0,
  filter: string | null,
  search: string | null,
  from: string | null,
  to: string | null,
  sortBy: string | null
): Promise<any> => {
  // Cancel previous request if it exists
  if (apiAbortController) {
    apiAbortController.abort();
  }

  // Create a new AbortController for the current request
  apiAbortController = new AbortController();
  try {
    const url = new URL("/api/leads", baseURL);
    if (limit && limit !== null && !isNaN(limit)) {
      url.searchParams.append("limit", limit.toString());
    }
    if (skip && skip !== null && !isNaN(skip)) {
      url.searchParams.append("skip", skip.toString());
    }
    if (filter !== null) {
      const urlSearch = new URLSearchParams(filter);
      urlSearch.forEach((value, key) =>
        url.searchParams.append(key, value.toString())
      );
    }

    if (filter !== null && search !== "") {
      url.searchParams.append("search", String(search));
    }
    if (to !== "" && to !== null) {
      url.searchParams.append("endDate", to);
    }
    if (from !== "" && from !== null) {
      url.searchParams.append("startDate", from);
    }
    if (sortBy === "updatedAt") url.searchParams.append("sortBy", "UPDATED_AT");

    const tags = [
      "leads",
      limit.toString(),
      skip.toString(),
      filter ?? "",
      search ?? "",
      from ?? "",
      to ?? "",
      sortBy ?? "",
    ];
    const response = await AUTH_GET(url.toString(), {
      revalidate: 30,
      tags: tags,
      signal: apiAbortController?.signal,
    });
    apiAbortController = null; // Clear the controller after successful request

    return response;
  } catch (error: any) {
    console.log({ error });
    if (error.name === "AbortError") {
      // Handle fetch cancellation
      console.log("Fetch request canceled");
    } else {
      // Handle other errors
      throw error;
    }
  }
};

export default getLeads;
