import getLeadDetails from "./getLeadDetails";
import getCount from "./getCount";
import getStageCount from "./getStageCount";
import getLeads from "./getLeads";
import getConfig from "./getConfig";
import getCustomModule from "./getCustomModule";
import getTasksForLeadId from "./getTasksForLeadId";
import getUserGroup from "./getUserGroup";
import getUserRole from "./getUserRole";
import getUser from "./getUser";
import getActivities from "./getActivities";
import getFilesForLeadId from "./getFilesForLeadId";
import getRequestsForLeadId from "./getRequestsForLeadId";
import getNotesForLeadId from "./getNotesForLeadId";

export {
  getLeadDetails,
  getCount,
  getStageCount,
  getLeads,
  getConfig,
  getCustomModule,
  getTasksForLeadId,
  getUserGroup,
  getUserRole,
  getUser,
  getActivities,
  getFilesForLeadId,
  getRequestsForLeadId,
  getNotesForLeadId,
};
