import { AUTH_GET } from "@grudder/app/actions-client";
const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

let apiAbortController: AbortController | null = null;

const getUserRole = async (roleId: string) => {
  // Cancel previous request if it exists
  if (apiAbortController) {
    apiAbortController.abort();
  }

  // Create a new AbortController for the current request
  apiAbortController = new AbortController();
  try {
    const url = new URL(`${baseURL}/api/user-roles/${roleId}`);
    const response = await AUTH_GET(url.toString(), {
      revalidate: 60,
      tags: ["user-roles", roleId],
      signal: apiAbortController?.signal,
    });
    apiAbortController = null; // Clear the controller after successful request

    return response;
  } catch (error: any) {
    console.log({ error });
    if (error.name === "AbortError") {
      // Handle fetch cancellation
      console.log("Fetch request canceled");
    } else {
      // Handle other errors
      throw error;
    }
  }
};

export default getUserRole;
