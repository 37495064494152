import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getConfig } from "@grudder/apiCalls";

// Define the Config interface based on the API response
interface Config {
  _id: string;
  source: string;
  attributes: Record<string, any>;
  settings?: Record<string, any>[];
  createdAt: string;
  updatedAt: string;
}

// Define the initial state for the config slice
interface ConfigState {
  configs: Config[];
  total: number;
  loading: boolean;
  error: string | null;
}

const initialState: ConfigState = {
  configs: [],
  total: 0,
  loading: false,
  error: null,
};

// Create an async thunk to fetch configs
export const fetchConfigs = createAsyncThunk<
  { data: Config[]; total: number },
  string | undefined
>("configs/fetchConfigs", async (ConfigType, { rejectWithValue }) => {
  try {
    const response = await getConfig(ConfigType);
    return response;
  } catch (error: any) {
    console.error("Failed to fetch configs:", error);
    return rejectWithValue(error.message || "Unknown error occurred");
  }
});

// Create the config slice
const configSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    initializeConfigs(
      state,
      action: PayloadAction<{ data: Config[]; total: number }>
    ) {
      state.configs = action.payload.data;
      state.total = action.payload.total;
    },
    resetConfigs(state) {
      state.configs = [];
      state.total = 0;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConfigs.fulfilled, (state, action) => {
        state.loading = false;
        state.configs = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchConfigs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(reset, () => initialState); // Handle reset action;
  },
});

// Export actions and reducer
export const { initializeConfigs, resetConfigs } = configSlice.actions;
export default configSlice.reducer;
