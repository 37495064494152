import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getUser } from "@grudder/apiCalls";

interface UsersState {
  allowedUsers: string[];
  allUsers: any[];
  loading: boolean;
  error: string | null;
  singleton: any | null;
  listLoading: boolean;
}

// Define the initial state
const initialState: UsersState = {
  allowedUsers: [],
  allUsers: [],
  loading: false,
  error: null,
  singleton: null,
  listLoading: true,
};
const getUserPermissions = (
  defaultPermissions: any,
  userId: string,
  roleId: string
) => {
  const userPermissions = defaultPermissions.filter((permission: any) =>
    permission.user.find((usr: any) => userId === usr._id)
  );
  const rolePermissions = defaultPermissions.filter((permission: any) =>
    permission.allowedUserTypes.find(
      (allowedUserType: any) => roleId === allowedUserType._id
    )
  );
  if (userPermissions?.length !== 0)
    return { isCustom: true, permissions: userPermissions };
  return { isCustom: false, permissions: rolePermissions };
};
const formatPermissions = (
  permissions: any,
  userId: string,
  userRoleId: string,
  editorId: string,
  editorRoleId: string
) => {
  const defaultPermissions = permissions.allPermissions;
  const { isCustom: isCurrentUserCustom, permissions: currentUserPermissions } =
    getUserPermissions(defaultPermissions, userId, userRoleId);
  const { permissions: editorPermissions } = getUserPermissions(
    defaultPermissions,
    editorId,
    editorRoleId
  );
  const editorPermissionsEnabled = defaultPermissions.map((permission: any) => {
    const hasAnEditorPermission = editorPermissions.find(
      (editorPermission: any) => editorPermission._id === permission._id
    );
    if (!hasAnEditorPermission) return { ...permission, disabled: true };
    return { ...hasAnEditorPermission, disabled: false };
  });
  const permissionsWithValues = editorPermissionsEnabled.map(
    (permission: any) => {
      const hasCurrentPermission = currentUserPermissions.find(
        (p: any) => p._id === permission._id
      );

      if (!hasCurrentPermission) return { ...permission, value: false };
      return {
        ...hasCurrentPermission,
        value: true,
        disabled: permission?.disabled,
      };
    }
  );

  return { isCustom: isCurrentUserCustom, permissions: permissionsWithValues };
};

const formatUser = (
  user: any,
  permissions: any,
  userId: string,
  userRoleId: string,
  editorId: string,
  editorRoleId: string
) => {
  const userGroupIds = user?.userGroups?.map((group: any) => group._id);

  const { isCustom: enableCustomPermissions, permissions: userPermissions } =
    formatPermissions(permissions, userId, userRoleId, editorId, editorRoleId);
  const formattedUser = {
    _id: user?._id,
    fullName: user.fullName,
    phone: user.phone || "",
    email: user.email || "",
    userRole: user?.role?._id,
    userGroups: userGroupIds,
    createdAt: user.createdAt,
    permissions: userPermissions,
    enableCustomPermissions: enableCustomPermissions,
  };
  return formattedUser;
};
// Define an async thunk to fetch user details
export const fetchUserDetails = createAsyncThunk(
  "users/fetchUserDetails",
  async (
    {
      userId,
      editorId,
      editorRoleId,
    }: {
      userId: string;
      editorId: string;
      editorRoleId: string;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as {
      users: UsersState;
      permissions: any;
    };

    const existingUser = state.users.allUsers.find(
      (user: any) => user._id === userId
    );
    if (existingUser) {
      const payload = formatUser(
        existingUser,
        state.permissions,
        userId,
        existingUser?.role?._id,
        editorId,
        editorRoleId
      );
      dispatch(updateUserDetails(payload));
      return payload;
    } else {
      const response = await getUser(userId);
      const payload = formatUser(
        response,
        state.permissions,
        userId,
        response?.role?._id,
        editorId,
        editorRoleId
      );
      dispatch(updateUserDetails(payload));
      return payload;
    }
  }
);

// Define the slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAllUsers: (state, action: PayloadAction<any[]>) => {
      const userMap = new Map(state.allUsers.map((user) => [user._id, user]));

      action.payload.forEach((user) => {
        const currentUser = userMap.get(user._id);
        if (
          currentUser &&
          new Date(currentUser.updatedAt).getTime() >=
            new Date(user.updatedAt).getTime()
        ) {
          return;
        }
        userMap.set(user._id, user);
      });

      const updatedUsers = Array.from(userMap.values());
      state.allUsers = updatedUsers;
      state.listLoading = false;
    },
    setAllowedUsers: (state, action: PayloadAction<any[]>) => {
      state.allowedUsers = action.payload.map((user) => user._id);
    },
    addUser: (state, action: PayloadAction<any>) => {
      const existingIndex = state.allUsers.findIndex(
        (user) => user._id === action.payload._id
      );
      if (existingIndex !== -1) {
        state.allUsers[existingIndex] = action.payload;
      } else {
        state.allUsers.push(action.payload);
      }
      if (!state.allowedUsers.includes(action.payload._id)) {
        state.allowedUsers.push(action.payload._id);
      }
    },
    removeUser: (state, action: PayloadAction<string>) => {
      state.allowedUsers = state.allowedUsers.filter(
        (id) => id !== action.payload
      );
      state.allUsers = state.allUsers.filter(
        (user) => user._id !== action.payload
      );
    },
    updateUser: (state, action: PayloadAction<any>) => {
      const index = state.allUsers.findIndex(
        (user) => user._id === action.payload._id
      );
      if (index !== -1) {
        state.allUsers[index] = action.payload;
      } else {
        state.allUsers.push(action.payload);
      }
    },
    updateUserDetails: (state, action: PayloadAction<any | null>) => {
      state.singleton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchUserDetails.pending, (state) => {
        console.log("pending:here");
        state.loading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        console.log("success:here");
        state.loading = false;
        state.singleton = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        console.log("failed:here");
        state.loading = false;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  setAllUsers,
  setAllowedUsers,
  addUser,
  removeUser,
  updateUser,
  updateUserDetails,
} = usersSlice.actions;

export default usersSlice.reducer;
