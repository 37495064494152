import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface SourceState {
  sources: any[];
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: SourceState = {
  sources: [],
  loading: false,
  error: null,
};

// Define the slice
const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    setSources: (state, action: PayloadAction<any[]>) => {
      state.sources = action.payload;
    },
    addSource: (state, action: PayloadAction<any>) => {
      state.sources = [...state.sources, action.payload];
    },
    updateSource: (state, action: PayloadAction<any>) => {
      const updatedSource = action.payload;
      const index = state.sources.findIndex(
        (source) => source._id === updatedSource._id
      );
      if (index !== -1) {
        state.sources[index] = updatedSource;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setSources, addSource, updateSource } = sourceSlice.actions;

export default sourceSlice.reducer;
