import { AUTH_GET } from "@grudder/app/actions-client";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL!;

let apiAbortController: AbortController | null = null;

const getNotesForLeadId = async (
  leadId: string,
  limit: number = 10,
  skip: number = 0
) => {
  // Cancel previous request if it exists
  if (apiAbortController) {
    apiAbortController.abort();
  }

  // Create a new AbortController for the current request
  apiAbortController = new AbortController();
  try {
    const url = new URL(
      `${baseURL}/api/notes?lead=${leadId}&limit=${limit}&skip=${skip}`
    );
    const response = await AUTH_GET(url.toString(), {
      revalidate: 60,
      tags: ["lead", leadId, `${leadId}-notes`],
      signal: apiAbortController?.signal,
    });
    apiAbortController = null; // Clear the controller after successful request
    return response;
  } catch (error: any) {
    console.log({ error });
    if (error.name === "AbortError") {
      // Handle fetch cancellation
      console.log("Fetch request canceled");
    } else {
      // Handle other errors
      throw error;
    }
  }
};

export default getNotesForLeadId;
