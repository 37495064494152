// enquirySlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getActivities } from "@grudder/apiCalls";

interface Enquiries {
  _id: string;
  leadId: string;
  createdAt: string;
}

interface EnquiriesState {
  enquiries: Enquiries[];
  total: number;
  loading: boolean;
  loadingMore: boolean;
}

const initialState: EnquiriesState = {
  enquiries: [],
  total: 0,
  loading: false,
  loadingMore: false,
};
const activityTypes = ["LEAD_SOURCE_HISTORY"];
const NUMBER_OF_ENQUIRIES_TO_FETCH = 10;

// Define an async thunk to fetch lead details
export const fetchEnquiriesForLead = createAsyncThunk<
  { data: Enquiries[]; total: number },
  string
>("enquiries/fetchEnquiriesForLead", async (leadId, { getState, dispatch }) => {
  const enquiriesState = getState() as { enquiries: EnquiriesState };
  const enquiries: Enquiries[] = enquiriesState.enquiries?.enquiries ?? [];
  const enquiriesTotal: number = enquiriesState.enquiries?.total ?? 0;

  const enquiriesForTheLeadExist =
    enquiries.length > 0 &&
    enquiries.every((enquiry) => enquiry.leadId === leadId);
  console.log({ enquiriesForTheLeadExist });
  if (!enquiriesForTheLeadExist) {
    dispatch(startEnquiryLoading());
    dispatch(initializeEnquiries({ enquiries: [], total: 0 }));
    const response = await getActivities(
      NUMBER_OF_ENQUIRIES_TO_FETCH,
      0,
      leadId,
      activityTypes
    );
    const data = response.data.map((enquiry: any) => ({
      ...enquiry,
      leadId: leadId,
    }));
    return { data, total: response.total };
  }

  const returnData = { data: enquiries, total: enquiriesTotal };
  return returnData;
});

// Define an async thunk to fetch lead details
export const loadMoreEnquiriesForLead = createAsyncThunk<
  { data: Enquiries[]; total: number },
  { leadId: string; limit: number; skip: number }
>("enquiries/loadMoreEnquiriesForLead", async ({ leadId, limit, skip }) => {
  const response = await getActivities(limit, skip, leadId, activityTypes);
  const data = response.data.map((enquiry: any) => ({
    ...enquiry,
    leadId: leadId,
  }));
  return { data, total: response.total };
});

const enquirySlice = createSlice({
  name: "enquiries",
  initialState,
  reducers: {
    initializeEnquiries(
      state,
      action: PayloadAction<{ enquiries: Enquiries[]; total: number }>
    ) {
      state.enquiries = [...action.payload.enquiries].sort(
        (enquiryA, enquiryB) =>
          new Date(enquiryB.createdAt).getTime() -
          new Date(enquiryA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
    startEnquiryLoading(state) {
      state.loading = true;
    },
    addNewEnquiry(state, action: PayloadAction<Enquiries>) {
      const enquiryIndex = state.enquiries.findIndex(
        (enquiry) => enquiry._id === action.payload._id
      );
      const updatedEnquiriesMap = new Map(
        state.enquiries.map((enquiry) => [enquiry._id, enquiry])
      );
      updatedEnquiriesMap.set(action.payload._id, action.payload);
      state.enquiries = Array.from(updatedEnquiriesMap.values()).sort(
        (enquiryA, enquiryB) =>
          new Date(enquiryB.createdAt).getTime() -
          new Date(enquiryA.createdAt).getTime()
      );
      if (enquiryIndex === -1) {
        state.total += 1;
      }
    },
    handleAddEnquiries(
      state,
      action: PayloadAction<{ enquiries: Enquiries[]; total: number }>
    ) {
      const updatedEnquiriesMap = new Map(
        state.enquiries.map((enquiry) => [enquiry._id, enquiry])
      );
      action.payload.enquiries.forEach((newEnquiry) => {
        updatedEnquiriesMap.set(newEnquiry._id, newEnquiry);
      });
      state.enquiries = Array.from(updatedEnquiriesMap.values()).sort(
        (enquiryA, enquiryB) =>
          new Date(enquiryB.createdAt).getTime() -
          new Date(enquiryA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnquiriesForLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEnquiriesForLead.fulfilled, (state, action) => {
        state.loading = false;
        state.enquiries = [...action.payload.data].sort(
          (enquiryA, enquiryB) =>
            new Date(enquiryB.createdAt).getTime() -
            new Date(enquiryA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(fetchEnquiriesForLead.rejected, (state) => {
        state.loading = false;
        state.enquiries = [];
        state.total = 0;
      })
      .addCase(loadMoreEnquiriesForLead.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(loadMoreEnquiriesForLead.fulfilled, (state, action: any) => {
        state.loadingMore = false;
        const updatedEnquiriesMap = new Map(
          state.enquiries.map((enquiry) => [enquiry._id, enquiry])
        );
        action.payload.data.forEach((newEnquiry: Enquiries) => {
          updatedEnquiriesMap.set(newEnquiry._id, newEnquiry);
        });
        state.enquiries = Array.from(updatedEnquiriesMap.values()).sort(
          (enquiryA, enquiryB) =>
            new Date(enquiryB.createdAt).getTime() -
            new Date(enquiryA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(loadMoreEnquiriesForLead.rejected, (state) => {
        state.loadingMore = false;
        state.enquiries = [];
        state.total = 0;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  initializeEnquiries,
  addNewEnquiry,
  handleAddEnquiries,
  startEnquiryLoading,
} = enquirySlice.actions;
export default enquirySlice.reducer;
