// fileSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getFilesForLeadId } from "@grudder/apiCalls";

interface File {
  _id: string;
  lead?: { _id: string };
  createdAt: string;
}

interface FileState {
  files: File[];
  total: number;
  loading: boolean;
  loadingMore: boolean;
}

const initialState: FileState = {
  files: [],
  total: 0,
  loading: false,
  loadingMore: false,
};

// Define an async thunk to fetch lead details
export const fetchFilesForLead = createAsyncThunk<
  { data: File[]; total: number },
  { leadId: string; filterCat?: string }
>(
  "files/fetchFilesForLead",
  async ({ leadId, filterCat }, { getState, dispatch }) => {
    const filesState = getState() as { files: FileState };
    const files: File[] = filesState.files?.files ?? [];
    const filesTotal: number = filesState.files?.total ?? 0;

    // Simplify the condition
    const filesForTheLeadExist =
      files.length > 0 && files.every((file) => file.lead?._id === leadId);

    if (!filesForTheLeadExist) {
      dispatch(startFileLoading());
      dispatch(initializeFiles({ files: [], total: 0 }));
      const response = await getFilesForLeadId(leadId, 10, 0, filterCat);
      return response;
    }

    const returnData = { data: files, total: filesTotal };
    return returnData;
  }
);

// Define an async thunk to fetch lead details
export const loadMoreFilesForLead = createAsyncThunk<
  { data: File[]; total: number },
  { leadId: string; limit: number; skip: number; filterCat?: string }
>("files/loadMoreFilesForLead", async ({ leadId, limit, skip, filterCat }) => {
  const response = await getFilesForLeadId(leadId, limit, skip, filterCat);
  return response;
});

const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    initializeFiles(
      state,
      action: PayloadAction<{ files: File[]; total: number }>
    ) {
      state.files = [...action.payload.files].sort(
        (fileA, fileB) =>
          new Date(fileB.createdAt).getTime() -
          new Date(fileA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
    startFileLoading(state) {
      state.loading = true;
    },
    addNewFile(state, action: PayloadAction<File>) {
      const fileIndex = state.files.findIndex(
        (file) => file._id === action.payload._id
      );
      const updatedFilesMap = new Map(
        state.files.map((file) => [file._id, file])
      );
      updatedFilesMap.set(action.payload._id, action.payload);
      state.files = Array.from(updatedFilesMap.values()).sort(
        (fileA, fileB) =>
          new Date(fileB.createdAt).getTime() -
          new Date(fileA.createdAt).getTime()
      );
      if (fileIndex === -1) {
        state.total += 1;
      }
    },
    handleAddFiles(
      state,
      action: PayloadAction<{ files: File[]; total: number }>
    ) {
      const updatedFilesMap = new Map(
        state.files.map((file) => [file._id, file])
      );
      action.payload.files.forEach((newFile) => {
        updatedFilesMap.set(newFile._id, newFile);
      });
      state.files = Array.from(updatedFilesMap.values()).sort(
        (fileA, fileB) =>
          new Date(fileB.createdAt).getTime() -
          new Date(fileA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilesForLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFilesForLead.fulfilled, (state, action) => {
        state.loading = false;
        state.files = [...action.payload.data].sort(
          (fileA, fileB) =>
            new Date(fileB.createdAt).getTime() -
            new Date(fileA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(fetchFilesForLead.rejected, (state) => {
        state.loading = false;
        state.files = [];
        state.total = 0;
      })
      .addCase(loadMoreFilesForLead.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(loadMoreFilesForLead.fulfilled, (state, action: any) => {
        state.loadingMore = false;
        const updatedFilesMap = new Map(
          state.files.map((file) => [file._id, file])
        );
        action.payload.data.forEach((newFile: File) => {
          updatedFilesMap.set(newFile._id, newFile);
        });
        state.files = Array.from(updatedFilesMap.values()).sort(
          (fileA, fileB) =>
            new Date(fileB.createdAt).getTime() -
            new Date(fileA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(loadMoreFilesForLead.rejected, (state) => {
        state.loadingMore = false;
        state.files = [];
        state.total = 0;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const { initializeFiles, addNewFile, handleAddFiles, startFileLoading } =
  fileSlice.actions;
export default fileSlice.reducer;
