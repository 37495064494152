import { getUserGroup } from "@grudder/apiCalls";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface UserGroupsState {
  allUserGroups: any[];
  allowedUserGroups: string[]; // Changed to string[] to store only IDs
  loading: boolean;
  listLoading: boolean;
  error: string | null;
  singleton: any | null;
}

// Define the initial state
const initialState: UserGroupsState = {
  allUserGroups: [],
  allowedUserGroups: [], // Now stores only IDs
  listLoading: false,
  loading: false,
  error: null,
  singleton: null,
};

// Define an async thunk to fetch user group details
export const fetchUserGroupDetails = createAsyncThunk(
  "userGroups/fetchUserGroupDetails",
  async (groupId: string, { getState, dispatch }) => {
    const state = getState() as { userGroups: UserGroupsState };
    const allUserGroups: any[] = state.userGroups.allUserGroups;

  

    const existingGroup = allUserGroups.find(
      (userGroup: any) => userGroup._id === groupId
    );
    if (existingGroup) {
      dispatch(updateUserGroupDetails(existingGroup));
      return existingGroup;
    } else {
      const response = await getUserGroup(groupId);
      return response;
    }
  }
);
// Define the slice
const userGroupsSlice = createSlice({
  name: "userGroups",
  initialState,
  reducers: {
    setAllUserGroups: (state, action: PayloadAction<any[]>) => {
      const userGroupMap = new Map(
        state.allUserGroups.map((userGroup) => [userGroup._id, userGroup])
      );

      action.payload.forEach((userGroup) => {
        const currentUserGroup = userGroupMap.get(userGroup._id);
        if (
          currentUserGroup &&
          new Date(currentUserGroup.updatedAt).getTime() >=
            new Date(userGroup.updatedAt).getTime()
        ) {
          return;
        }
        userGroupMap.set(userGroup._id, userGroup);
      });

      const updatedUserGroups = Array.from(userGroupMap.values());
      state.allUserGroups = updatedUserGroups;
      state.listLoading = false;
    },
    setAllowedUserGroups: (state, action: PayloadAction<any[]>) => {
      state.allowedUserGroups = action.payload.map((group) => group._id);
    },
    addUserGroup: (state, action: PayloadAction<any>) => {
      const existingIndex = state.allUserGroups.findIndex(
        (group) => group._id === action.payload._id
      );
      if (existingIndex !== -1) {
        state.allUserGroups[existingIndex] = action.payload;
      } else {
        state.allUserGroups.push(action.payload);
      }
      if (!state.allowedUserGroups.includes(action.payload._id)) {
        state.allowedUserGroups.push(action.payload._id);
      }
    },
    removeUserGroup: (state, action: PayloadAction<string>) => {
      state.allowedUserGroups = state.allowedUserGroups.filter(
        (id) => id !== action.payload
      );
      state.allUserGroups = state.allUserGroups.filter(
        (group) => group._id !== action.payload
      );
    },
    updateUserGroup: (state, action: PayloadAction<any>) => {
      const index = state.allUserGroups.findIndex(
        (userGroup) => userGroup._id === action.payload._id
      );
      if (index !== -1) {
        state.allUserGroups[index] = action.payload;
      } else {
        state.allUserGroups.push(action.payload);
        state.allowedUserGroups.push(action.payload._id);
      }
    },
    updateUserGroupDetails: (state, action: PayloadAction<any | null>) => {
      state.singleton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGroupDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserGroupDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.singleton = action.payload;
      })
      .addCase(fetchUserGroupDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(reset, () => initialState);
  },
});

export const {
  setAllUserGroups,
  setAllowedUserGroups,
  addUserGroup,
  removeUserGroup,
  updateUserGroup,
  updateUserGroupDetails,
} = userGroupsSlice.actions;

export default userGroupsSlice.reducer;
