"use client";
import store, { persistor } from "@grudder/redux/store";
import { Loader } from "@mantine/core";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
export const handleDispatch = (data: any) => {
  store.dispatch(data);
};
function ReduxProvider({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<Loader type="gr-initial-loader" />}
        persistor={persistor}
      >
        {children}
      </PersistGate>
    </Provider>
  );
}

export default ReduxProvider;
