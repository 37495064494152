// requestSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getRequestsForLeadId } from "@grudder/apiCalls";

interface Request {
  _id: string;
  lead?: { _id: string };
  createdAt: string;
}

interface RequestState {
  request: Request[];
  total: number;
  loading: boolean;
  loadingMore: boolean;
}

const initialState: RequestState = {
  request: [],
  total: 0,
  loading: false,
  loadingMore: false,
};

// Define an async thunk to fetch lead details
export const fetchRequestsForLead = createAsyncThunk<
  { data: Request[]; total: number },
  string
>("request/fetchRequestsForLead", async (leadId, { getState, dispatch }) => {
  const requestsState = getState() as { request: RequestState };
  const request: Request[] = requestsState.request?.request ?? [];
  const requestsTotal: number = requestsState.request?.total ?? 0;

  // Simplify the condition
  const requestsForTheLeadExist =
    request.length > 0 &&
    request.every((request) => request.lead?._id === leadId);

  if (!requestsForTheLeadExist) {
    dispatch(startRequestLoading());
    dispatch(initializeRequests({ request: [], total: 0 }));
    const response = await getRequestsForLeadId(leadId);
    return response;
  }

  const returnData = { data: request, total: requestsTotal };
  return returnData;
});

// Define an async thunk to fetch lead details
export const loadMoreRequestsForLead = createAsyncThunk<
  { data: Request[]; total: number },
  { leadId: string; limit: number; skip: number }
>("request/loadMoreRequestsForLead", async ({ leadId, limit, skip }) => {
  const response = await getRequestsForLeadId(leadId, limit, skip);
  return response;
});

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    initializeRequests(
      state,
      action: PayloadAction<{ request: Request[]; total: number }>
    ) {
      state.request = [...action.payload.request].sort(
        (requestA, requestB) =>
          new Date(requestB.createdAt).getTime() -
          new Date(requestA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
    startRequestLoading(state) {
      state.loading = true;
    },
    addNewRequest(state, action: PayloadAction<Request>) {
      const requestIndex = state.request.findIndex(
        (request) => request._id === action.payload._id
      );
      const updatedRequestsMap = new Map(
        state.request.map((request) => [request._id, request])
      );
      updatedRequestsMap.set(action.payload._id, action.payload);
      state.request = Array.from(updatedRequestsMap.values()).sort(
        (requestA, requestB) =>
          new Date(requestB.createdAt).getTime() -
          new Date(requestA.createdAt).getTime()
      );
      if (requestIndex === -1) {
        state.total += 1;
      }
    },
    handleAddRequests(
      state,
      action: PayloadAction<{ request: Request[]; total: number }>
    ) {
      const updatedRequestsMap = new Map(
        state.request.map((request) => [request._id, request])
      );
      action.payload.request.forEach((newRequest) => {
        updatedRequestsMap.set(newRequest._id, newRequest);
      });
      state.request = Array.from(updatedRequestsMap.values()).sort(
        (requestA, requestB) =>
          new Date(requestB.createdAt).getTime() -
          new Date(requestA.createdAt).getTime()
      );
      state.total = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestsForLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRequestsForLead.fulfilled, (state, action) => {
        state.loading = false;
        state.request = [...action.payload.data].sort(
          (requestA, requestB) =>
            new Date(requestB.createdAt).getTime() -
            new Date(requestA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(fetchRequestsForLead.rejected, (state) => {
        state.loading = false;
        state.request = [];
        state.total = 0;
      })
      .addCase(loadMoreRequestsForLead.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(loadMoreRequestsForLead.fulfilled, (state, action: any) => {
        state.loadingMore = false;
        const updatedRequestsMap = new Map(
          state.request.map((request) => [request._id, request])
        );
        action.payload.data.forEach((newRequest: Request) => {
          updatedRequestsMap.set(newRequest._id, newRequest);
        });
        state.request = Array.from(updatedRequestsMap.values()).sort(
          (requestA, requestB) =>
            new Date(requestB.createdAt).getTime() -
            new Date(requestA.createdAt).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(loadMoreRequestsForLead.rejected, (state) => {
        state.loadingMore = false;
        state.request = [];
        state.total = 0;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  initializeRequests,
  addNewRequest,
  handleAddRequests,
  startRequestLoading,
} = requestSlice.actions;
export default requestSlice.reducer;
