import { AUTH_GET } from "@grudder/app/actions-server";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL!;

const getStageCount = async (tags: string[]) => {
  try {
    const url = new URL(`${baseURL}/api/dashboards/lead-counts/stages`);

    const data = await AUTH_GET(url.toString(), {
      revalidate: 120,
      tags: tags,
    });
    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
export default getStageCount;
