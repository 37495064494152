// store/taskSlice.ts
import { getTasksForLeadId } from "@grudder/apiCalls";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface Task {
  _id: string;
  lead?: { _id: string };
  date: string;
}
interface TaskState {
  tasks: Task[];
  total: number;
  loading: boolean;
  loadingMore: boolean;
}

const initialState: TaskState = {
  tasks: [],
  total: 0,
  loading: false,
  loadingMore: false,
};

// Define an async thunk to fetch lead details
export const fetchTasksForLead = createAsyncThunk<
  { data: Task[]; total: number },
  string
>("tasks/fetchTasksForLead", async (leadId, { getState, dispatch }) => {
  const taskState = getState() as { tasks: TaskState };
  const tasks: Task[] = taskState.tasks?.tasks ?? [];
  const tasksTotal: number = taskState.tasks?.total ?? 0;

  // Simplify the condition
  const tasksForTheLeadExist =
    tasks.length > 0 && tasks.every((task) => task.lead?._id === leadId);

  if (!tasksForTheLeadExist) {
    dispatch(startTaskLoading());
    dispatch(initializeTasks({ tasks: [], total: 0 }));
    const response = await getTasksForLeadId(leadId);
    return response;
  }
  const returnData = { data: tasks, total: tasksTotal };
  return returnData;
});

// Define an async thunk to fetch lead details
export const loadMoreTasksForLead = createAsyncThunk<
  { data: Task[]; total: number },
  { leadId: string; limit: number; skip: number }
>("tasks/loadMoreTasksForLead", async ({ leadId, limit, skip }) => {
  const response = await getTasksForLeadId(leadId, limit, skip);
  return response;
});

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    InitializeTasks(
      state,
      action: PayloadAction<{ tasks: any[]; total: number }>
    ) {
      state.tasks = [...action.payload.tasks].sort(
        (taskA, taskB) =>
          new Date(taskB.date).getTime() - new Date(taskA.date).getTime()
      );
      state.total = action.payload.total;
    },
    startTaskLoading(state) {
      state.loading = true;
    },

    addNewTask(state, action: PayloadAction<any>) {
      const taskIndex = state.tasks.findIndex(
        (task) => task._id === action.payload._id
      );
      const updatedTasksMap = new Map(
        state.tasks.map((task) => [task._id, task])
      );
      updatedTasksMap.set(action.payload._id, action.payload);
      state.tasks = Array.from(updatedTasksMap.values()).sort(
        (taskA: any, taskB: any) =>
          new Date(taskB.date).getTime() - new Date(taskA.date).getTime()
      );
      if (taskIndex === -1) {
        state.total += 1;
      }
    },
    handleAddTasks(
      state,
      action: PayloadAction<{ tasks: any[]; total: number }>
    ) {
      const updatedTasksMap = new Map(
        state.tasks.map((task) => [task._id, task])
      );
      action.payload.tasks.forEach((newTask) => {
        updatedTasksMap.set(newTask._id, newTask);
      });
      state.tasks = Array.from(updatedTasksMap.values()).sort(
        (taskA: any, taskB: any) =>
          new Date(taskB.date).getTime() - new Date(taskA.date).getTime()
      );
      state.total = action.payload.total;
    },
    updateTask(state, action: PayloadAction<any>) {
      const updatedTask = action.payload;
      const taskIndex = state.tasks.findIndex(
        (task) => task._id === updatedTask._id
      );

      if (taskIndex !== -1) {
        // Update the task if it already exists
        state.tasks[taskIndex] = updatedTask;
      } else {
        // Add the new task if it doesn't exist
        state.tasks.push(updatedTask);
        state.total += 1;
      }

      // Sort tasks by creation date
      state.tasks.sort(
        (taskA: any, taskB: any) =>
          new Date(taskB.date).getTime() - new Date(taskA.date).getTime()
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasksForLead.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTasksForLead.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = [...action.payload.data].sort(
          (taskA, noteB) =>
            new Date(noteB.date).getTime() - new Date(taskA.date).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(fetchTasksForLead.rejected, (state) => {
        state.loading = false;
        state.tasks = [];
        state.total = 0;
      })
      .addCase(loadMoreTasksForLead.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(loadMoreTasksForLead.fulfilled, (state, action: any) => {
        state.loadingMore = false;
        const updatedNotesMap = new Map(
          state.tasks.map((task) => [task._id, task])
        );
        action.payload.data.forEach((newTask: Task) => {
          updatedNotesMap.set(newTask._id, newTask);
        });
        state.tasks = Array.from(updatedNotesMap.values()).sort(
          (taskA, noteB) =>
            new Date(noteB.date).getTime() - new Date(taskA.date).getTime()
        );
        state.total = action.payload.total;
      })
      .addCase(loadMoreTasksForLead.rejected, (state) => {
        state.loadingMore = false;
        state.tasks = [];
        state.total = 0;
      })
      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  InitializeTasks: initializeTasks,
  addNewTask,
  handleAddTasks,
  updateTask,
  startTaskLoading,
} = taskSlice.actions;
export default taskSlice.reducer;
