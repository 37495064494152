import { getLeadDetails } from "@grudder/apiCalls";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface DealsState {
  currentDealIds: string[];
  deals: any[];
  total: number;
  loading: boolean;
  error: any | null;
  listLoading: string | null;
}

const initialState: DealsState = {
  currentDealIds: [],
  deals: [],
  total: 0,
  loading: false,
  error: null,
  listLoading: null,
};

// Define an async thunk to fetch lead details
export const fetchDealDetails = createAsyncThunk(
  "deals/fetchDealDetails",
  async (dealId: string) => {
    const response = await getLeadDetails(dealId);
    return response;
  }
);

const dealSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setInitialDeals: (
      state,
      action: PayloadAction<{ data: any[]; total: number }>
    ) => {
      const dealMap = new Map(state.deals.map((deal) => [deal.id, deal]));
      action.payload.data.forEach((deal) => {
        // check if current is latest
        const currentDeal = dealMap.get(deal.id);
        if (
          currentDeal &&
          new Date(currentDeal.updatedAt).getTime() >=
            new Date(deal.updatedAt).getTime()
        ) {
          return;
        }
        dealMap.set(deal.id, deal);
      });

      state.deals = Array.from(dealMap.values());
      state.currentDealIds = action.payload.data.map((deal) => deal.id);
      state.total = action.payload.total;
    },
    updateDealDetails: (state, action: PayloadAction<any | null>) => {
      // Create a Map from the current leads array for quick lookups
      const dealsMap = new Map(state.deals.map((deal) => [deal._id, deal]));

      const newDeal = action.payload;
      const existingDeal = dealsMap.get(newDeal._id);

      // If the lead exists and the new lead data is newer, update the Map
      if (existingDeal) {
        if (
          new Date(existingDeal.updatedAt).getTime() <=
          new Date(newDeal.updatedAt).getTime()
        ) {
          console.log(
            "New lead is newer, updating the existing lead in the Map"
          );
          dealsMap.set(newDeal._id, newDeal);
        } else {
          console.log("Existing deal is newer, keeping the old data");
        }
      } else {
        // If the lead does not exist, add it to the Map
        console.log("Deal does not exist, adding new deal to the Map");
        dealsMap.set(newDeal._id, newDeal);
      }

      // Convert the Map back to an array and update the state
      state.deals = Array.from(dealsMap.values());
    },
    setListLoading: (state, action: PayloadAction<string | null>) => {
      state.listLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealDetails.pending, (state, action) => {
        state.loading = true;
        const existingDeal = state.deals.find(
          (lead: any) => lead._id === action.meta.arg
        );
        if (existingDeal) {
          state.loading = false;
        }
      })
      .addCase(fetchDealDetails.fulfilled, (state, action) => {
        // Create a Map from the current leads array for quick lookups
        const dealsMap = new Map(state.deals.map((deal) => [deal._id, deal]));

        const newDeal = action.payload;
        const existingDeal = dealsMap.get(newDeal._id);

        // If the lead exists and the new lead data is newer, update the Map
        if (existingDeal) {
          if (
            new Date(existingDeal.updatedAt).getTime() <=
            new Date(newDeal.updatedAt).getTime()
          ) {
            console.log(
              "New lead is newer, updating the existing lead in the Map"
            );
            dealsMap.set(newDeal._id, newDeal);
          } else {
            console.log("Existing deal is newer, keeping the old data");
          }
        } else {
          // If the lead does not exist, add it to the Map
          console.log("Deal does not exist, adding new deal to the Map");
          dealsMap.set(newDeal._id, newDeal);
        }

        // Convert the Map back to an array and update the state
        state.deals = Array.from(dealsMap.values());
      })
      .addCase(fetchDealDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setInitialDeals, updateDealDetails, setListLoading } =
  dealSlice.actions;

export default dealSlice.reducer;
