// actionSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface Actions {
  _id: string;
  name: string;
  type: string;
  status: "inprogress" | "completed" | "failed" | "ignored";
}
const initialState: { actions: Actions[] } = {
  actions: [],
};

const actionSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    addAction: (state, action: PayloadAction<Actions>) => {
      const existingAction = state.actions.find(
        (actionItem) => actionItem._id === action.payload._id
      );
      if (existingAction) {
        existingAction.status = action.payload.status;
      } else {
        state.actions = [...state.actions, action.payload];
      }
    },
    updateAction: (state, action: PayloadAction<Actions>) => {
      const existingAction = state.actions.find(
        (actionItem) => actionItem._id === action.payload._id
      );
      if (existingAction) {
        existingAction.status = action.payload.status;
      }
    },
    removeAction: (state, action: PayloadAction<string>) => {
      const existingAction = state.actions.find(
        (actionItem) => actionItem._id === action.payload
      );
      if (existingAction) {
        existingAction.status = "ignored";
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { addAction, updateAction, removeAction } = actionSlice.actions;
export default actionSlice.reducer;
