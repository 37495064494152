import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface StagesState {
  stages: any[];
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: StagesState = {
  stages: [],
  loading: false,
  error: null,
};

// Define the slice
const stagesSlice = createSlice({
  name: "stages",
  initialState,
  reducers: {
    setStages: (state, action: PayloadAction<any[]>) => {
      state.stages = action.payload
        .slice()
        .sort((a, b) => a.weightAge - b.weightAge);
    },
    addStage: (state, action: PayloadAction<any>) => {
      state.stages = [...state.stages, action.payload].sort(
        (a, b) => a.weightAge - b.weightAge
      );
    },
    updateStage: (state, action: PayloadAction<any>) => {
      const updatedStage = action.payload;
      const index = state.stages.findIndex(
        (stage) => stage._id === updatedStage._id
      );
      if (index !== -1) {
        state.stages[index] = updatedStage;
        state.stages = state.stages.sort((a, b) => a.weightAge - b.weightAge);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setStages, addStage, updateStage } = stagesSlice.actions;

export default stagesSlice.reducer;
