import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
interface PrimarySourceState {
  primarySources: any[];
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: PrimarySourceState = {
  primarySources: [],
  loading: false,
  error: null,
};

// Define the slice
const primarySourceSlice = createSlice({
  name: "primarySource",
  initialState,
  reducers: {
    setPrimarySources: (state, action: PayloadAction<any[]>) => {
      state.primarySources = action.payload;
    },
    addPrimarySource: (state, action: PayloadAction<any>) => {
      state.primarySources = [...state.primarySources, action.payload];
    },
    updatePrimarySource: (state, action: PayloadAction<any>) => {
      const updatedPrimarySource = action.payload;
      const index = state.primarySources.findIndex(
        (primarySource) => primarySource._id === updatedPrimarySource._id
      );
      if (index !== -1) {
        state.primarySources[index] = updatedPrimarySource;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setPrimarySources, addPrimarySource, updatePrimarySource } =
  primarySourceSlice.actions;

export default primarySourceSlice.reducer;
