"use client";
import { queryClient } from "@grudder/providers/QueryClientProviders";
import { getSession } from "next-auth/react";

// Common types and functions
type HttpMethod = "GET" | "POST" | "PUT";
type NextConfig = Record<string, unknown>;

const getAuthHeaders = (session: any): HeadersInit => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${session?.accessToken}`,
});

const handleResponse = async (res: Response) => {
  if (!res.ok) {
    if (res?.status === 401) {
      console.log("Signing out");
      window.location.href = "/session-expired";
    }
    console.log("handleResponse", res)
    const error = await res.json();
    throw { ...error, errorCode: res.status };
  }
  return res.json();
};

const makeAuthRequest = async (
  method: HttpMethod,
  url: string,
  payload?: any,
  nextConfig?: NextConfig
) => {
  console.log(method, url);
  const session: any = await getSession();
  if (!session?.accessToken) {
    throw new Error("No session found");
  }

  const options: RequestInit = {
    method,
    headers: getAuthHeaders(session),
    next: { revalidate: 0, ...nextConfig },
  };

  if (payload && method !== "GET") {
    options.body = JSON.stringify(payload);
  }

  const res = await fetch(url, options);
  return handleResponse(res);
};

// Exported functions
export const AUTH_GET = (url: string, nextConfig?: NextConfig) => {
  const tags = Array.isArray(nextConfig?.tags) ? nextConfig.tags : [];
  console.log({ tags });
  return queryClient.fetchQuery({
    queryKey: tags,
    queryFn: () => makeAuthRequest("GET", url, undefined, nextConfig),
  });
};

export const AUTH_POST = async (
  url: string,
  payload: any,
  nextConfig?: NextConfig
) => makeAuthRequest("POST", url, payload, nextConfig);

export const AUTH_PUT = async (
  url: string,
  payload: any,
  nextConfig?: NextConfig
) => makeAuthRequest("PUT", url, payload, nextConfig);
