import { AUTH_GET } from "@grudder/app/actions-server";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL!;

const getCount = async (
  startDate: string | null,
  endDate: string | null,
  tags: string[]
) => {
  try {
    const url = new URL(`${baseURL}/api/dashboards/lead-counts`);
    if (startDate) url.searchParams.set("startDate", startDate);
    if (endDate) url.searchParams.set("endDate", endDate);

    const data = await AUTH_GET(url.toString(), {
      revalidate: 120,
      tags: tags,
    });
    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export default getCount;
