import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface PermissionState {
  allPermissions: any[];
  userPermissions: any[];
}

// Define the initial state
const initialState: PermissionState = {
  allPermissions: [],
  userPermissions: [],
};

// Define the slice
const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setUserPermissions: (state, action: PayloadAction<any[]>) => {
      state.userPermissions = action.payload;
    },
    setAllPermissions: (state, action: PayloadAction<any[]>) => {
      state.allPermissions = action.payload;
    },
    updateAllPermissions: (state, action: PayloadAction<any[]>) => {
      const allPermissionMap = new Map(
        state.allPermissions.map((allPermission) => [
          allPermission.id,
          allPermission,
        ])
      );
      //loop current permissions and update the one that has changed
      action.payload.forEach((permission) => {
        allPermissionMap.set(permission._id, permission);
      });
      state.allPermissions = Array.from(allPermissionMap.values());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setAllPermissions, setUserPermissions, updateAllPermissions } =
  permissionSlice.actions;

export default permissionSlice.reducer;
